module.exports = (DateTime) => {
	return {
		props: ['details'],
		template: `
			<span>
				<img v-bind:src="getSun(details)[0]" v-bind:alt="getSun(details)[1]">
				{{ processTime(details) }}
			</span>
		`,
		methods: {
			processTime: (details) => {
				try {
					const dateTimeNow = DateTime.local().setZone(details.location.tz_id);
					if (dateTimeNow.invalid === null) {
						return `${dateTimeNow.toLocaleString(DateTime.TIME_SIMPLE)}`;
					} else {
						return dateTimeNow;
					}
				} catch (error) {
					return 'No timezone'
				}
			},
			getSun: (details) => {
				try {
					const dateTimeNow = DateTime.local().setZone(details.location.tz_id);
					const sunriseTime = DateTime.fromString(details.astronomy.astro.sunrise, 'hh:mm a');
					const sunsetTime = DateTime.fromString(details.astronomy.astro.sunset, 'hh:mm a');
					const dateTimeSunrise = dateTimeNow.set({
						hour: sunriseTime.hour,
						minute: sunriseTime.minute,
						second: sunriseTime.second
					});
					const dateTimeSunset = dateTimeNow.set({
						hour: sunsetTime.hour,
						minute: sunsetTime.minute,
						second: sunsetTime.second
					});

					if (dateTimeSunrise < dateTimeNow && dateTimeNow < dateTimeSunset) {
						return ['/assets/sun.svg', 'sun logo'];
					} else {
						return ['/assets/moon.svg', 'moon logo'];
					}
				} catch (error) {
					return '';
				}	
			}
		}
	}
}
