module.exports = {
	props: ['score'],
	template: `<span v-html="renderScore(score)"></span>`,
    methods: {
		normalizeScore(score) {
			return score * (10/30);
		},
		renderScore(score) {
			const scoreNormalize = this.normalizeScore(score).toFixed(0);
			let scoreRender = [];
			
			for (let index = 0; index < 10; index++) {
				let scoreClass = 'www-list-item-score-block-inactive';
				if (index <= scoreNormalize) {
					scoreClass = 'www-list-item-score-block-active';
				}
				
				scoreRender.push(`<div class="${scoreClass}"></div>`);
			}

			return scoreRender.join('');
		}
    }
}