// functions
const parseCountry = require('../functions/parseCountry');
const parseWeather = require('../functions/parseWeather');

module.exports = {
	props: ['details'],
	template: `
		<div>
			<span class="www-list-item-value-location" v-html="formatCity(details)"></span>
			<span class="www-list-item-value-weather" v-html="formatWeather(details)"></span>
		</div>
	`,
	methods: {
		formatCity: (details) => {
			if (!details) {
				return 'No location data available'
			}

			let resultString = ``;
			
			if (details.location.name) {
				resultString = resultString.concat(`${details.location.name}`);
			}

			if (details.location.region) {
				resultString = resultString.concat(`, ${details.location.region}`);
			}

			if (details.location.country) {
				const countryParsed = parseCountry(details.location.country)
				resultString = resultString.concat(`<br>${countryParsed}`);
			}

			// if (details.annotations.flag) {
			// 	resultString = resultString.concat(` ${details.annotations.flag}`);
			// }
			
			return resultString;
		},
		formatWeather: (details) => {
			try {
				return parseWeather(details.current.condition.text);
			} catch (error) {
				return ''
			}
		}
	}
};
