module.exports = {
	props: ['visibility', 'unit'],
	template: `<span>{{ showVis(visibility, unit) }}</span>`,
    methods: {
		showVis: function (visibility, unit) {
			if (visibility && !isNaN(visibility)) {
				if (unit === "km") {
					return `${(visibility * 1.609).toFixed(2)} km.`;
				}

				return `${parseFloat(visibility).toFixed(2)} mi.`;
			}

			return '';
		}
    }
}