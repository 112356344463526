module.exports = {
	props: ['temp', 'unit'],
	template: `<span>{{ convert(temp, unit) }}° {{ unit }}</span>`,
    methods: {
        convert: (temp, unit) => {
            if (temp) {
                if (unit === "F") {
                    return parseInt((temp * 9/5) + 32);
                }

                return parseInt(temp);
            }
            
            return '';
        }
    }
}