module.exports = (weather) => {
	const weatherListMapped = {
		'Sunny': 'Windy',
		'Clear': 'Windy'
	}

	if (weatherListMapped[weather]) {
		return weatherListMapped[weather];
	}
	
	return weather;
}