module.exports = (country) => {
	const countryListMapped = {
		'United States of America': 'USA'
	}

	if (countryListMapped[country]) {
		return countryListMapped[country];
	}
	
	return country;
}