const cookiesMessage = `This site uses cookies to analyse traffic, remember your preferences and optimise your experience.`;

module.exports = {
	template: `
		<div class="www-cookies" v-if="renderCookies()" :key="componentKey">
			<p>
				${cookiesMessage}
			</p>
			<button class="www-cookies-button" v-on:click="acceptCookies">
				Got it
			</button>
		</div>
	`,
	data() {
		return {
			componentKey: 0,
		};
	},
	methods: {
		renderCookies() {
			if (localStorage &&
				localStorage.cookies &&
				localStorage.cookies == "true") {
				return false;
			}

			return true;
		},
		acceptCookies() {
			localStorage.cookies = true;
			this.forceRerender();
		},
		forceRerender() {
			this.componentKey += 1;
		}
	}
};