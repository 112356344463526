module.exports = {
	props: ['details'],
	template: `
	<span>
		{{details.wind_speed_kt}} kt.
		<br/>
		{{ gust(details.score.wind) }}
	</span>`,
	methods: {
        gust: (wind) => {
			if (wind.gustStr) {
				return `${wind.gustStr}${(wind.gustNumber) ? ` (${wind.gustNumber} kt.)` : ''}`;
			}
		}
	}
}